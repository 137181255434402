var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            [
              _c("head-layout", {
                attrs: {
                  "head-btn-options": _vm.headBtnOptions,
                  "head-title": _vm.$t(
                    "cip.plat.sys.visual3.title.indexHeadTitle"
                  ),
                },
                on: {
                  "head-remove": _vm.headRemove,
                  "head-add-tabs": _vm.headAddTabs,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("grid-head-layout", {
        ref: "gridHeadLayout",
        attrs: {
          "grid-head-btn": _vm.gridHeadBtn,
          "search-columns": _vm.searchColumns,
        },
        on: {
          "grid-head-search": _vm.gridHeadSearch,
          "grid-head-empty": _vm.gridHeadEmpty,
        },
      }),
      _c("grid-layout", {
        ref: "gridLayOut",
        attrs: {
          "data-total": _vm.page.total,
          "grid-row-btn": _vm.gridRowBtn,
          page: _vm.page,
          "table-data": _vm.tableData,
          "table-loading": _vm.tableLoading,
          "table-options": _vm.tableOptions,
        },
        on: {
          "gird-handle-select-click": _vm.selectionChange,
          "page-current-change": _vm.onLoad,
          "page-size-change": _vm.onLoad,
          "page-refresh-change": _vm.onLoad,
          "row-remove": _vm.rowRemove,
          "row-preview": _vm.preview,
          "row-role": _vm.rolePermission,
          "row-edit": _vm.addOrUpdateHandle,
        },
        scopedSlots: _vm._u([
          {
            key: "customBtn",
            fn: function ({ row }) {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { type: "text", size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.rowEnable(row)
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        row.enabledMark == 1
                          ? _vm.$t("cip.cmn.btn.enable")
                          : _vm.$t("cip.cmn.btn.disable")
                      ) + "\n      "
                    ),
                  ]
                ),
                row.enabledMark == 2
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDefault(row)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(row.isDefault == 1 ? "非默认" : "默认") +
                            "\n      "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c(
        "el-dialog",
        {
          staticClass: "SNOWS-dialog SNOWS-dialog-add SNOWS-dialog_center",
          attrs: {
            visible: _vm.dialogVisible,
            "lock-scroll": "",
            title: _vm.$t("cip.plat.sys.visual3.field.addPortal"),
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "add-main" }, [
            _c(
              "div",
              {
                staticClass: "add-item add-item-left",
                on: {
                  click: function ($event) {
                    return _vm.addOrUpdateHandle(1)
                  },
                },
              },
              [
                _c("i", { staticClass: "add-icon icon-ym icon-ym-customUrl" }),
                _c("div", { staticClass: "add-txt" }, [
                  _c("p", { staticClass: "add-title" }, [
                    _vm._v(
                      _vm._s(_vm.$t("cip.plat.sys.visual3.field.customPath"))
                    ),
                  ]),
                  _c("p", { staticClass: "add-desc" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("cip.plat.sys.visual3.field.confCustomPath")
                      )
                    ),
                  ]),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "add-item",
                on: {
                  click: function ($event) {
                    return _vm.addOrUpdateHandle(0)
                  },
                },
              },
              [
                _c("i", { staticClass: "add-icon icon-ym icon-ym-pageDesign" }),
                _c("div", { staticClass: "add-txt" }, [
                  _c("p", { staticClass: "add-title" }, [
                    _vm._v(_vm._s(_vm.$t("cip.plat.sys.visual3.field.page"))),
                  ]),
                  _c("p", { staticClass: "add-desc" }, [
                    _vm._v(_vm._s(_vm.$t("cip.plat.sys.visual3.field.drag"))),
                  ]),
                ]),
              ]
            ),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("cip.plat.sys.visual3.title.allocationTitle"),
            visible: _vm.roleDialogVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.roleDialogVisible = $event
            },
          },
        },
        [
          _c("avue-input-tree", {
            attrs: {
              size: "mini",
              dataType: "string",
              clearable: "",
              multiple: "",
              placeholder: _vm.$t(
                "cip.plat.workflow.components.userOption.field.role"
              ),
              dic: _vm.roleList,
              props: _vm.roleProps || { label: "roleName", value: "id" },
            },
            model: {
              value: _vm.roleIds,
              callback: function ($$v) {
                _vm.roleIds = $$v
              },
              expression: "roleIds",
            },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.roleDialogVisible = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("cip.cmn.btn.celBtn")))]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.roleHandle } },
                [_vm._v(_vm._s(_vm.$t("cip.cmn.btn.defBtn")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.formVisible
        ? _c("Form", { ref: "form", on: { close: _vm.closeForm } })
        : _vm._e(),
      _vm.form1Visible
        ? _c("Form1", { ref: "form1", on: { close: _vm.closeForm1 } })
        : _vm._e(),
      _c("Preview", {
        attrs: { id: _vm.activeId, visible: _vm.previewVisible },
        on: {
          "update:visible": function ($event) {
            _vm.previewVisible = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }