var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "SNOWS-dialog SNOWS-dialog_center",
      attrs: {
        title: _vm.$t("cip.plat.sys.portal.field.addPortal"),
        "close-on-click-modal": false,
        visible: _vm.visible,
        "lock-scroll": "",
        width: "600px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "dataForm",
          attrs: {
            model: _vm.dataForm,
            rules: _vm.dataRule,
            "label-width": "80px",
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("cip.plat.sys.portal.field.name"),
                prop: "fullName",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  placeholder: _vm.$t("cip.plat.sys.portal.field.name"),
                  maxlength: "100",
                },
                model: {
                  value: _vm.dataForm.fullName,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "fullName", $$v)
                  },
                  expression: "dataForm.fullName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("cip.plat.sys.portal.field.enCode"),
                prop: "enCode",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  placeholder: _vm.$t("cip.plat.sys.portal.field.enCode"),
                  maxlength: "50",
                },
                model: {
                  value: _vm.dataForm.enCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "enCode", $$v)
                  },
                  expression: "dataForm.enCode",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("cip.plat.sys.portal.field.portal"),
                prop: "category",
              },
            },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: _vm.$t("cip.plat.sys.portal.field.portal"),
                  },
                  model: {
                    value: _vm.dataForm.category,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataForm, "category", $$v)
                    },
                    expression: "dataForm.category",
                  },
                },
                _vm._l(_vm.categoryList, function (item) {
                  return _c("el-option", {
                    key: item.dictKey,
                    attrs: { label: item.dictValue, value: item.dictKey },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("cip.plat.sys.portal.field.linkType"),
                prop: "linkType",
              },
            },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: _vm.$t("cip.plat.sys.portal.field.linkType"),
                  },
                  model: {
                    value: _vm.dataForm.linkType,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataForm, "linkType", $$v)
                    },
                    expression: "dataForm.linkType",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "页面", value: 0 } }),
                  _c("el-option", { attrs: { label: "外链", value: 1 } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("cip.plat.sys.portal.field.linkPath"),
                prop: "customUrl",
              },
            },
            [
              _c(
                "el-input",
                {
                  attrs: {
                    placeholder: _vm.$t("cip.plat.sys.portal.field.linkPath"),
                  },
                  model: {
                    value: _vm.dataForm.customUrl,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataForm, "customUrl", $$v)
                    },
                    expression: "dataForm.customUrl",
                  },
                },
                [
                  _vm.dataForm.linkType === 0
                    ? _c("template", { slot: "prepend" }, [_vm._v("@/views/")])
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("cip.plat.sys.portal.field.sort"),
                prop: "sortCode",
              },
            },
            [
              _c("el-input-number", {
                attrs: { min: 0, max: 999999, "controls-position": "right" },
                model: {
                  value: _vm.dataForm.sortCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "sortCode", $$v)
                  },
                  expression: "dataForm.sortCode",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("cip.plat.sys.portal.field.remark"),
                prop: "description",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  placeholder: _vm.$t("cip.plat.sys.portal.field.remark"),
                  type: "textarea",
                  rows: 3,
                },
                model: {
                  value: _vm.dataForm.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "description", $$v)
                  },
                  expression: "dataForm.description",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("cip.cmn.btn.celBtn")))]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.btnLoading },
              on: {
                click: function ($event) {
                  return _vm.dataFormSubmit()
                },
              },
            },
            [_vm._v("\n        " + _vm._s(_vm.$t("cip.cmn.btn.defBtn")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }