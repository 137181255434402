var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "SNOWS-full-dialog",
      attrs: {
        visible: _vm.visible,
        fullscreen: "",
        "lock-scroll": "",
        "show-close": false,
        modal: false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "SNOWS-full-dialog-header" },
        [
          _c("div", { staticClass: "header-title" }, [
            _c("img", {
              staticClass: "header-logo",
              attrs: { src: require("@/assets/images/sinomas.png") },
            }),
            _c("p", { staticClass: "header-txt" }, [
              _vm._v(
                " · " +
                  _vm._s(_vm.$t("cip.plat.sys.portal.title.indexHeadTitle")) +
                  " "
              ),
            ]),
          ]),
          _c(
            "el-steps",
            {
              staticClass: "steps steps2",
              attrs: {
                active: _vm.activeStep,
                "finish-status": "success",
                simple: "",
              },
            },
            [
              _c("el-step", {
                attrs: { title: _vm.$t("cip.plat.sys.portal.field.basicSet") },
                nativeOn: {
                  click: function ($event) {
                    return _vm.stepChick(0)
                  },
                },
              }),
              _c("el-step", {
                attrs: {
                  title: _vm.$t("cip.plat.sys.portal.title.indexHeadTitle"),
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.stepChick(1)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "options" },
            [
              _c(
                "el-button",
                {
                  attrs: { disabled: _vm.activeStep <= 0 },
                  on: { click: _vm.prev },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("cip.plat.sys.dataInterface.field.prev"))
                  ),
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: { disabled: _vm.activeStep >= 1 || _vm.loading },
                  on: { click: _vm.next },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("cip.plat.sys.dataInterface.field.next")) +
                      "\n      "
                  ),
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    disabled: _vm.activeStep != 1,
                    loading: _vm.btnLoading,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.dataFormSubmit()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("cip.cmn.btn.defBtn")))]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.closeDialog()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("cip.cmn.btn.celBtn")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "main",
        },
        [
          !_vm.activeStep
            ? _c(
                "el-row",
                {
                  staticClass: "basic-box",
                  attrs: { type: "flex", justify: "center", align: "middle" },
                },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "basicForm",
                      attrs: { xs: 12, sm: 12, md: 12, lg: 12, xl: 10 },
                    },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "dataForm",
                          attrs: {
                            model: _vm.dataForm,
                            rules: _vm.dataRule,
                            "label-width": "80px",
                            "label-position": "right",
                          },
                          nativeOn: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("cip.plat.sys.portal.field.name"),
                                prop: "fullName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "cip.plat.sys.portal.field.name"
                                  ),
                                  maxlength: "100",
                                },
                                model: {
                                  value: _vm.dataForm.fullName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "fullName", $$v)
                                  },
                                  expression: "dataForm.fullName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "cip.plat.sys.portal.field.enCode"
                                ),
                                prop: "enCode",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "cip.plat.sys.portal.field.enCode"
                                  ),
                                  maxlength: "50",
                                },
                                model: {
                                  value: _vm.dataForm.enCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "enCode", $$v)
                                  },
                                  expression: "dataForm.enCode",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "cip.plat.sys.portal.field.portal"
                                ),
                                prop: "category",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "cip.plat.sys.portal.field.portal"
                                    ),
                                  },
                                  model: {
                                    value: _vm.dataForm.category,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dataForm, "category", $$v)
                                    },
                                    expression: "dataForm.category",
                                  },
                                },
                                _vm._l(_vm.categoryList, function (item) {
                                  return _c("el-option", {
                                    key: item.dictKey,
                                    attrs: {
                                      label: item.dictValue,
                                      value: item.dictKey,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("cip.plat.sys.portal.field.sort"),
                                prop: "sortCode",
                              },
                            },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  min: 0,
                                  max: 999999,
                                  "controls-position": "right",
                                },
                                model: {
                                  value: _vm.dataForm.sortCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "sortCode", $$v)
                                  },
                                  expression: "dataForm.sortCode",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "cip.plat.sys.portal.field.status"
                                ),
                                prop: "enabledMark",
                              },
                            },
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-value": 2,
                                  "inactive-value": 1,
                                },
                                model: {
                                  value: _vm.dataForm.enabledMark,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "enabledMark", $$v)
                                  },
                                  expression: "dataForm.enabledMark",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "cip.plat.sys.portal.field.remark"
                                ),
                                prop: "description",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "cip.plat.sys.portal.field.remark"
                                  ),
                                  type: "textarea",
                                  rows: 3,
                                },
                                model: {
                                  value: _vm.dataForm.description,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "description", $$v)
                                  },
                                  expression: "dataForm.description",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.activeStep == 1
            ? [
                _c("PortalDesigner", {
                  ref: "portalDesigner",
                  attrs: { conf: _vm.formData },
                }),
              ]
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }