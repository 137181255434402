<template>
  <el-dialog :visible.sync="visible" fullscreen lock-scroll class="SNOWS-full-dialog"
    :show-close="false" :modal="false">
    <div class="SNOWS-full-dialog-header">
      <div class="header-title">
        <img src="@/assets/images/sinomas.png" class="header-logo" />
        <p class="header-txt"> · {{ $t('cip.plat.sys.portal.title.indexHeadTitle') }} </p>
      </div>
      <el-steps :active="activeStep" finish-status="success" simple class="steps steps2">
        <el-step :title="$t('cip.plat.sys.portal.field.basicSet')" @click.native="stepChick(0)"></el-step>
        <el-step :title="$t('cip.plat.sys.portal.title.indexHeadTitle')" @click.native="stepChick(1)"></el-step>
      </el-steps>
      <div class="options">
        <el-button @click="prev" :disabled="activeStep<=0">{{$t('cip.plat.sys.dataInterface.field.prev')}}</el-button>
        <el-button @click="next" :disabled="activeStep>=1 || loading">{{$t('cip.plat.sys.dataInterface.field.next')}}
        </el-button>
        <el-button type="primary" @click="dataFormSubmit()" :disabled="activeStep!=1"
          :loading="btnLoading">{{$t('cip.cmn.btn.defBtn')}}</el-button>
        <el-button @click="closeDialog()">{{$t('cip.cmn.btn.celBtn')}}</el-button>
      </div>
    </div>
    <div class="main" v-loading="loading">
      <el-row type="flex" justify="center" align="middle" v-if="!activeStep" class="basic-box">
        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="10" class="basicForm">
          <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="80px"
            @submit.native.prevent label-position="right">
            <el-form-item :label="$t('cip.plat.sys.portal.field.name')" prop="fullName">
              <el-input v-model="dataForm.fullName" :placeholder="$t('cip.plat.sys.portal.field.name')" maxlength="100">
              </el-input>
            </el-form-item>
            <el-form-item :label="$t('cip.plat.sys.portal.field.enCode')" prop="enCode">
              <el-input v-model="dataForm.enCode" :placeholder="$t('cip.plat.sys.portal.field.enCode')" maxlength="50">
              </el-input>
            </el-form-item>
            <el-form-item :label="$t('cip.plat.sys.portal.field.portal')" prop="category">
              <el-select v-model="dataForm.category" :placeholder="$t('cip.plat.sys.portal.field.portal')">
                <el-option :key="item.dictKey" :label="item.dictValue" :value="item.dictKey"
                  v-for="item in categoryList" />
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('cip.plat.sys.portal.field.sort')" prop="sortCode">
              <el-input-number :min="0" :max="999999" v-model="dataForm.sortCode"
                controls-position="right" />
            </el-form-item>
            <el-form-item :label="$t('cip.plat.sys.portal.field.status')" prop="enabledMark">
              <el-switch v-model="dataForm.enabledMark" :active-value="2" :inactive-value="1" />
            </el-form-item>
            <el-form-item :label="$t('cip.plat.sys.portal.field.remark')" prop="description">
              <el-input v-model="dataForm.description" :placeholder="$t('cip.plat.sys.portal.field.remark')" type="textarea"
                :rows="3" />
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <template v-if="activeStep==1">
        <PortalDesigner ref="portalDesigner" :conf="formData" />
      </template>
    </div>
  </el-dialog>
</template>

<script>
import { getPortalInfo, Submit } from '@/api/onlineDev/portal'
import PortalDesigner from './components/index'
export default {
  components: { PortalDesigner },
  data() {
    return {
      visible: false,
      loading: false,
      activeStep: 0,
      dataForm: {
        id: '',
        fullName: '',
        enCode: '',
        sortCode: 0,
        enabledMark: 1,
        type: 0,
        linkType: null,
        customUrl: '',
        category: '',
        description: ""
      },
      dataRule: {
        fullName: [
          { required: true, message: this.$t('cip.plat.sys.portal.msg.name'), trigger: 'blur' },
          {
            max:60,
            message: this.$t('cip.cmn.rule.nameLength6Warning'),
          },
          {
            pattern: /^\S.*\S$|(^\S{0,1}\S$)/,
            message: this.$t('cip.cmn.rule.deleteWarning'),
          }
        ],
        enCode: [
          { required: true, message: this.$t('cip.plat.sys.portal.msg.enCode'), trigger: 'blur' },
          {
            max:50,
            message: this.$t('cip.cmn.rule.nameLength5Warning'),
          },
          {
            pattern: /^\S.*\S$|(^\S{0,1}\S$)/,
            message: this.$t('cip.cmn.rule.deleteWarning'),
          }
        ],
        category: [
          { required: true, message: this.$t('cip.plat.sys.portal.msg.portal'), trigger: 'change' },
        ]
      },
      formVisible: false,
      btnLoading: false,
      formData: null,
      categoryList: []
    }
  },
  methods: {
    init(categoryList, id) {
      this.categoryList = categoryList
      this.activeStep = 0
      this.dataForm.id = id || ''
      this.visible = true
      this.formData = null
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields()
        if (this.dataForm.id) {
          this.loading = true
          getPortalInfo(this.dataForm.id).then(res => {
            this.loading = false
            this.dataForm = res.data.data
            this.formData = JSON.parse(this.dataForm.formData)
          }).catch(() => { this.loading = false })
        }
      })
    },
    dataFormSubmit() {
      this.$refs['portalDesigner'].getData().then(res => {
        this.btnLoading = true
        this.formData = res.formData
        this.dataForm.formData = JSON.stringify(this.formData)
        Submit(this.dataForm).then((res) => {
          this.$message({
            message: this.$t('cip.cmn.msg.success.operateSuccess'),
            type: 'success',
            duration: 1500,
            onClose: () => {
              this.closeDialog(true)
            }
          })
        }).catch(() => { this.btnLoading = false })
      }).catch(err => {
        err.msg && this.$message.warning(err.msg)
      })
    },
    closeDialog(isRefresh) {
      this.visible = false
      this.$emit('close', isRefresh)
    },
    prev() {
      this.activeStep -= 1
    },
    next() {
      if (this.activeStep < 1) {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) this.activeStep += 1
        })
      }
    },
    stepChick(key) {
      if (this.activeStep <= key) return
      this.activeStep = key
    }
  }
}
</script>
