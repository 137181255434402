var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-container",
    { staticClass: "SNOWS-Portal" },
    [
      _c(
        "el-aside",
        { staticClass: "left-box", attrs: { width: "250px" } },
        [
          _c("el-scrollbar", { staticClass: "aside-scrollbar" }, [
            _c(
              "div",
              { staticClass: "components-list" },
              _vm._l(_vm.leftComponents, function (item, listIndex) {
                return _c(
                  "div",
                  { key: listIndex, staticClass: "components-part" },
                  [
                    _c("div", { staticClass: "components-title" }, [
                      _vm._v(_vm._s(item.title)),
                    ]),
                    _c(
                      "div",
                      { staticClass: "list" },
                      _vm._l(item.list, function (element, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "components-item",
                            on: {
                              click: function ($event) {
                                return _vm.addComponent(element)
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "components-body" }, [
                              _c("i", { class: element.icon }),
                              _vm._v(
                                "\n                " +
                                  _vm._s(element.label) +
                                  "\n              "
                              ),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                )
              }),
              0
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-main",
        { staticClass: "center-box" },
        [
          _c(
            "div",
            { staticClass: "action-bar" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-video-play",
                    size: "medium",
                    type: "text",
                  },
                  on: { click: _vm.preview },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("cip.plat.sys.visual3.btn.previewBtn")) +
                      "\n      "
                  ),
                ]
              ),
              _c(
                "el-button",
                {
                  staticClass: "delete-btn",
                  attrs: {
                    icon: "el-icon-delete",
                    size: "medium",
                    type: "text",
                  },
                  on: { click: _vm.empty },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("cip.plat.sys.visual3.btn.emptyBtn")) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-scrollbar",
            { staticClass: "layout-area" },
            [
              _c(
                "grid-layout",
                {
                  attrs: { layout: _vm.layout, "row-height": 40 },
                  on: {
                    "update:layout": function ($event) {
                      _vm.layout = $event
                    },
                  },
                },
                _vm._l(_vm.layout, function (item) {
                  return _c(
                    "grid-item",
                    {
                      key: item.i,
                      class: { "active-item": item.i === _vm.activeId },
                      attrs: {
                        h: item.h,
                        i: item.i,
                        maxH: item.maxH,
                        maxW: item.maxW,
                        minH: item.minH,
                        minW: item.minW,
                        w: item.w,
                        x: item.x,
                        y: item.y,
                      },
                      on: { resized: _vm.resizedEvent },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.handleClick(item)
                        },
                      },
                    },
                    [
                      item.snowsKey === "todo"
                        ? _c("Todo", { attrs: { title: item.title } })
                        : _vm._e(),
                      item.snowsKey === "commonFunc"
                        ? _c("CommonFunc", {
                            attrs: { list: item.list, title: item.title },
                          })
                        : _vm._e(),
                      item.snowsKey === "todoList"
                        ? _c("TodoList", { attrs: { title: item.title } })
                        : _vm._e(),
                      item.snowsKey === "notice"
                        ? _c("HNotice", { attrs: { title: item.title } })
                        : _vm._e(),
                      item.snowsKey === "email"
                        ? _c("HEmail", { attrs: { title: item.title } })
                        : _vm._e(),
                      item.snowsKey === "dataBoard"
                        ? _c("DataBoard", {
                            attrs: { list: item.list, title: item.title },
                          })
                        : _vm._e(),
                      item.snowsKey === "barChart"
                        ? _c("HBarChart", {
                            ref: "eChart" + item.i,
                            refInFor: true,
                            attrs: {
                              dataType: item.dataType,
                              option: item.option,
                              propsApi: item.propsApi,
                              title: item.title,
                            },
                          })
                        : _vm._e(),
                      item.snowsKey === "annularChart"
                        ? _c("HAnnularChart", {
                            ref: "eChart" + item.i,
                            refInFor: true,
                            attrs: {
                              dataType: item.dataType,
                              option: item.option,
                              propsApi: item.propsApi,
                              title: item.title,
                            },
                          })
                        : _vm._e(),
                      item.snowsKey === "areaChart"
                        ? _c("HAreaChart", {
                            ref: "eChart" + item.i,
                            refInFor: true,
                            attrs: {
                              dataType: item.dataType,
                              option: item.option,
                              propsApi: item.propsApi,
                              title: item.title,
                            },
                          })
                        : _vm._e(),
                      item.snowsKey === "lineChart"
                        ? _c("HLineChart", {
                            ref: "eChart" + item.i,
                            refInFor: true,
                            attrs: {
                              dataType: item.dataType,
                              option: item.option,
                              propsApi: item.propsApi,
                              title: item.title,
                            },
                          })
                        : _vm._e(),
                      item.snowsKey === "pieChart"
                        ? _c("HPieChart", {
                            ref: "eChart" + item.i,
                            refInFor: true,
                            attrs: {
                              dataType: item.dataType,
                              option: item.option,
                              propsApi: item.propsApi,
                              title: item.title,
                            },
                          })
                        : _vm._e(),
                      item.snowsKey === "radarChart"
                        ? _c("HRadarChart", {
                            ref: "eChart" + item.i,
                            refInFor: true,
                            attrs: {
                              dataType: item.dataType,
                              option: item.option,
                              propsApi: item.propsApi,
                              title: item.title,
                            },
                          })
                        : _vm._e(),
                      _c("div", { staticClass: "mask" }),
                      _c(
                        "span",
                        {
                          staticClass: "drawing-item-copy",
                          attrs: { title: "复制" },
                          on: {
                            click: function ($event) {
                              return _vm.addComponent(item)
                            },
                          },
                        },
                        [_c("i", { staticClass: "el-icon-copy-document" })]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "drawing-item-delete",
                          attrs: { title: "删除" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.handleRemoveItem(item.i)
                            },
                          },
                        },
                        [_c("i", { staticClass: "el-icon-delete" })]
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.layout.length,
                      expression: "!layout.length",
                    },
                  ],
                  staticClass: "empty-info",
                },
                [
                  _c("img", {
                    staticClass: "empty-img",
                    attrs: {
                      alt: "",
                      src: require("@/assets/images/emptyPortal.png"),
                    },
                  }),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("right-panel", { attrs: { "active-data": _vm.activeData } }),
      _c("Preview", {
        attrs: { layout: _vm.layout, visible: _vm.previewVisible },
        on: {
          "update:visible": function ($event) {
            _vm.previewVisible = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }