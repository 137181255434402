// 基础控件 【左面板】
import i18n from '@/lang/index'
export const basicComponents = [{
    icon: 'icon-ym icon-ym-generator-todo',
    label: i18n.t('cip.cmn.components.basicControl.title.myToDoListTitle'),
    snowsKey: 'todo',
    title: i18n.t('cip.cmn.components.basicControl.title.myToDoListTitle'),
    w: 6,
    h: 4,
    minW: 6,
    minH: 4,
    maxW: 12,
    maxH: 20
  },
  {
    icon: 'icon-ym icon-ym-generator-function',
    label: i18n.t('cip.cmn.components.basicControl.title.commonFunctionTitle'),
    snowsKey: 'commonFunc',
    title: i18n.t('cip.cmn.components.basicControl.title.commonFunctionTitle'),
    list: [
      { fullName: i18n.t('cip.cmn.components.basicControl.field.functionDesign'), id: "85cd7bca426e49ce83a061bf461b1447", urlAddress: "onlineDev/webDesign", icon: "icon-ym icon-ym-webDesign", iconBackgroundColor: "#FF3B3B", type: 2 },
      { fullName: i18n.t('cip.cmn.components.basicControl.field.processDesign'), id: "fe0150960dd542ec9328067e4495074b", urlAddress: "workFlow/flowEngine", icon: "icon-ym icon-ym-flowDesign", iconBackgroundColor: "#7B1AE1", type: 2 },
      { fullName: i18n.t('cip.cmn.components.basicControl.field.rightManagement'), id: "9057d797550e4457b7ddf2456ce45882", urlAddress: "permission/authorize", icon: "icon-ym icon-ym-authorize", iconBackgroundColor: "#1890FF", type: 2 },
      { fullName: i18n.t('cip.cmn.components.basicControl.field.reportDesign'), id: "c7159f97177b420d9fc8ec8c74ae541b", urlAddress: "onlineDev/dataReport", icon: "icon-ym icon-ym-reportDesign", iconBackgroundColor: "#F68900", type: 2 },
      { fullName: i18n.t('cip.cmn.components.basicControl.field.portalDesign'), id: "232d7226f00446d882e1e1bd999f7b2d", urlAddress: "onlineDev/visualPortal", icon: "icon-ym icon-ym-portalDesign", iconBackgroundColor: "#33B932", type: 2 }
    ],
    w: 6,
    h: 4,
    minW: 6,
    minH: 4,
    maxW: 12,
    maxH: 20
  },
  {
    icon: 'icon-ym icon-ym-generator-notice',
    label: i18n.t('cip.cmn.components.basicControl.title.noticeTitle'),
    snowsKey: 'notice',
    title: i18n.t('cip.cmn.components.basicControl.title.noticeTitle'),
    w: 4,
    h: 7,
    minW: 4,
    minH: 7,
    maxW: 12,
    maxH: 20
  },
  {
    icon: 'icon-ym icon-ym-generator-email',
    label: i18n.t('cip.cmn.components.basicControl.title.weatherForecastTitle'),
    snowsKey: 'email',
    title: i18n.t('cip.cmn.components.basicControl.title.weatherForecastTitle'),
    w: 4,
    h: 7,
    minW: 4,
    minH: 7,
    maxW: 12,
    maxH: 20
  },
  {
    icon: 'icon-ym icon-ym-generator-todolist',
    label: i18n.t('cip.cmn.components.basicControl.title.todoListTitle'),
    snowsKey: 'todoList',
    title: i18n.t('cip.cmn.components.basicControl.title.todoListTitle'),
    w: 4,
    h: 7,
    minW: 4,
    minH: 7,
    maxW: 12,
    maxH: 20
  },
  {
    icon: 'icon-ym icon-ym-generator-Panel',
    label: i18n.t('cip.cmn.components.basicControl.title.dataPanelTitle'),
    snowsKey: 'dataBoard',
    list: [
      { fullName: i18n.t('cip.cmn.components.basicControl.field.custom'), num: 262, dataType: 'static', propsApi: '', icon: "icon-ym icon-ym-wf-contractApproval" },
      { fullName: i18n.t('cip.cmn.components.basicControl.field.business'), num: 199, dataType: 'static', propsApi: '', icon: "icon-ym icon-ym-wf-outgoingApply" },
      { fullName: i18n.t('cip.cmn.components.basicControl.field.contract'), num: 126263.5, dataType: 'static', propsApi: '', icon: "icon-ym icon-ym-wf-payDistribution" },
      { fullName: i18n.t('cip.cmn.components.basicControl.field.collection'), num: 6223.2, dataType: 'static', propsApi: '', icon: "icon-ym icon-ym-wf-incomeRecognition" }
    ],
    w: 12,
    h: 3,
    minW: 12,
    minH: 3,
    maxW: 12,
    maxH: 20
  }
]
// 图表控件 【左面板】
export const chartComponents = [{
    icon: 'icon-ym icon-ym-generator-bar',
    label: i18n.t('cip.cmn.components.basicControl.title.histogramTitle'),
    snowsKey: 'barChart',
    title: i18n.t('cip.cmn.components.basicControl.title.histogramTitle'),
    dataType: 'static',
    propsApi: '',
    option: {
      color: ['#1890ff'],
      title: {
        text: '',
        subtext: '',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: { type: 'shadow' }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: [{
        type: 'category',
        data: [i18n.t('cip.cmn.components.basicControl.field.January'), i18n.t('cip.cmn.components.basicControl.field.February'), 
        i18n.t('cip.cmn.components.basicControl.field.March'), i18n.t('cip.cmn.components.basicControl.field.April'), 
        i18n.t('cip.cmn.components.basicControl.field.May'), i18n.t('cip.cmn.components.basicControl.field.June')],
        axisTick: { alignWithLabel: true }
      }],
      yAxis: [{ type: 'value' }],
      series: [{
        name: i18n.t('cip.cmn.components.basicControl.field.salesVolume'),
        type: 'bar',
        barWidth: '60%',
        data: [1012, 500, 800, 868, 653, 372]
      }]
    },
    w: 6,
    h: 8,
    minW: 4,
    minH: 8,
    maxW: 12,
    maxH: 20
  },
  {
    icon: 'icon-ym icon-ym-generator-pie',
    label: i18n.t('cip.cmn.components.basicControl.title.pieTitle'),
    snowsKey: 'pieChart',
    title: i18n.t('cip.cmn.components.basicControl.title.pieTitle'),
    dataType: 'static',
    propsApi: '',
    option: {
      title: {
        text: i18n.t('cip.cmn.components.basicControl.field.weatherStatistics'),
        subtext: i18n.t('cip.cmn.components.basicControl.field.fictitiousData'),
        left: 'center'
      },
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b} : {c} ({d}%)'
      },
      legend: {
        // orient: 'vertical',
        // top: 'middle',
        bottom: 10,
        left: 'center',
        data: [i18n.t('cip.cmn.components.basicControl.field.houLiang'), i18n.t('cip.cmn.components.basicControl.field.yiZhou'), 
        i18n.t('cip.cmn.components.basicControl.field.yanZhou'), i18n.t('cip.cmn.components.basicControl.field.jingzhou'), 
        i18n.t('cip.cmn.components.basicControl.field.youzhou')]
      },
      series: [{
        type: 'pie',
        radius: '65%',
        center: ['50%', '50%'],
        selectedMode: 'single',
        data: [{ value: 1548, name: i18n.t('cip.cmn.components.basicControl.field.youZhou'), itemStyle: { color: '#1890ff' } },
          { value: 535, name: i18n.t('cip.cmn.components.basicControl.field.jingZhou'), itemStyle: { color: '#ff3b3b' } },
          { value: 510, name: i18n.t('cip.cmn.components.basicControl.field.yanZhou'), itemStyle: { color: '#7b1ae1' } },
          { value: 634, name: i18n.t('cip.cmn.components.basicControl.field.yiZhou'), itemStyle: { color: '#f68900' } },
          { value: 735, name: i18n.t('cip.cmn.components.basicControl.field.houLiang'), itemStyle: { color: '#33b932' } }
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }]
    },
    w: 6,
    h: 8,
    minW: 4,
    minH: 8,
    maxW: 12,
    maxH: 20
  },
  {
    icon: 'icon-ym icon-ym-generator-line',
    label: i18n.t('cip.cmn.components.basicControl.title.lineChartTitle'),
    snowsKey: 'lineChart',
    title: i18n.t('cip.cmn.components.basicControl.title.lineChartTitle'),
    dataType: 'static',
    propsApi: '',
    option: {
      title: {
        text: ''
      },
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: [i18n.t('cip.cmn.components.basicControl.field.email'), i18n.t('cip.cmn.components.basicControl.field.allianceAdvertising'), 
        i18n.t('cip.cmn.components.basicControl.field.videoAD'), i18n.t('cip.cmn.components.basicControl.field.directAccess'), 
        i18n.t('cip.cmn.components.basicControl.field.searchEngines')]
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      toolbox: {
        feature: {
          saveAsImage: {}
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: [i18n.t('cip.cmn.components.basicControl.field.Monday'), i18n.t('cip.cmn.components.basicControl.field.Tuesday'), 
        i18n.t('cip.cmn.components.basicControl.field.Wednesday'), i18n.t('cip.cmn.components.basicControl.field.Thursday'), 
        i18n.t('cip.cmn.components.basicControl.field.Friday'), i18n.t('cip.cmn.components.basicControl.field.Saturday'), 
        i18n.t('cip.cmn.components.basicControl.field.Sunday')]
      },
      yAxis: {
        type: 'value'
      },
      series: [{
          name: i18n.t('cip.cmn.components.basicControl.field.email'),
          type: 'line',
          stack: i18n.t('cip.cmn.components.basicControl.field.title'),
          data: [120, 132, 101, 134, 90, 230, 210],
          itemStyle: { color: '#33b932' }
        },
        {
          name: i18n.t('cip.cmn.components.basicControl.field.allianceAdvertising'),
          type: 'line',
          stack: i18n.t('cip.cmn.components.basicControl.field.title'),
          data: [220, 182, 191, 234, 290, 330, 310],
          itemStyle: { color: '#ff3b3b' }
        },
        {
          name: i18n.t('cip.cmn.components.basicControl.field.videoAD'),
          type: 'line',
          stack: i18n.t('cip.cmn.components.basicControl.field.title'),
          data: [150, 232, 201, 154, 190, 330, 410],
          itemStyle: { color: '#7b1ae1' }
        },
        {
          name: i18n.t('cip.cmn.components.basicControl.field.directAccess'),
          type: 'line',
          stack: i18n.t('cip.cmn.components.basicControl.field.title'),
          data: [320, 332, 301, 334, 390, 330, 320],
          itemStyle: { color: '#1890ff' }
        },
        {
          name: i18n.t('cip.cmn.components.basicControl.field.searchEngines'),
          type: 'line',
          stack: i18n.t('cip.cmn.components.basicControl.field.title'),
          data: [820, 932, 901, 934, 1290, 1330, 1320],
          itemStyle: { color: '#f68900' }
        }
      ]
    },
    w: 6,
    h: 8,
    minW: 4,
    minH: 8,
    maxW: 12,
    maxH: 20
  },
  {
    icon: 'icon-ym icon-ym-generator-annular',
    label: i18n.t('cip.cmn.components.basicControl.title.circularDiagramTitle'),
    snowsKey: 'annularChart',
    title: i18n.t('cip.cmn.components.basicControl.title.circularDiagramTitle'),
    dataType: 'static',
    propsApi: '',
    option: {
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b}: {c} ({d}%)'
      },
      legend: {
        orient: 'vertical',
        left: 10,
        data: [i18n.t('cip.cmn.components.basicControl.field.directAccess'), i18n.t('cip.cmn.components.basicControl.field.email'), 
        i18n.t('cip.cmn.components.basicControl.field.allianceAdvertising'), i18n.t('cip.cmn.components.basicControl.field.videoAD'), 
        i18n.t('cip.cmn.components.basicControl.field.searchEngines')]
      },
      series: [{
        name: i18n.t('cip.cmn.components.basicControl.field.referrer'),
        type: 'pie',
        radius: ['50%', '70%'],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'center'
        },
        emphasis: {
          label: {
            show: true,
            fontSize: '30',
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: [
          { value: 335, name: i18n.t('cip.cmn.components.basicControl.field.directAccess'), itemStyle: { color: '#33b932' } },
          { value: 310, name: i18n.t('cip.cmn.components.basicControl.field.email'), itemStyle: { color: '#ff3b3b' } },
          { value: 234, name: i18n.t('cip.cmn.components.basicControl.field.allianceAdvertising'), itemStyle: { color: '#7b1ae1' } },
          { value: 135, name: i18n.t('cip.cmn.components.basicControl.field.videoAD'), itemStyle: { color: '#f68900' } },
          { value: 1548, name: i18n.t('cip.cmn.components.basicControl.field.searchEngines'), itemStyle: { color: '#1890ff' } }
        ]
      }]
    },
    w: 6,
    h: 8,
    minW: 4,
    minH: 8,
    maxW: 12,
    maxH: 20
  },
  {
    icon: 'icon-ym icon-ym-generator-area',
    label: i18n.t('cip.cmn.components.basicControl.title.areaTitle'),
    snowsKey: 'areaChart',
    title: i18n.t('cip.cmn.components.basicControl.title.areaTitle'),
    dataType: 'static',
    propsApi: '',
    option: {
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
      },
      yAxis: {
        type: 'value'
      },
      series: [{
        data: [820, 932, 901, 934, 1290, 1330, 1320],
        type: 'line',
        areaStyle: {
          color: '#d0e2f3'
        },
        itemStyle: {
          normal: {
            color: '#1890FF',
            lineStyle: { color: '#1890FF' }
          }
        },
      }]
    },
    w: 6,
    h: 8,
    minW: 4,
    minH: 8,
    maxW: 12,
    maxH: 20
  },
  {
    icon: 'icon-ym icon-ym-generator-radar',
    label: i18n.t('cip.cmn.components.basicControl.title.radarChartTitle'),
    snowsKey: 'radarChart',
    title: i18n.t('cip.cmn.components.basicControl.title.radarChartTitle'),
    dataType: 'static',
    propsApi: '',
    option: {
      title: {
        text: i18n.t('cip.cmn.components.basicControl.title.radarChartTitle')
      },
      tooltip: {},
      legend: {
        data: [i18n.t('cip.cmn.components.basicControl.field.allocatedBudget'), i18n.t('cip.cmn.components.basicControl.field.actualSpending')]
      },
      radar: {
        shape: 'circle',
        radius: '80',
        name: {
          textStyle: {
            color: '#fff',
            backgroundColor: '#999',
            borderRadius: 3,
            padding: [3, 5]
          }
        },
        indicator: [
          { name: i18n.t('cip.cmn.components.basicControl.field.sales'), max: 6500 },
          { name: i18n.t('cip.cmn.components.basicControl.field.administration'), max: 16000 },
          { name: i18n.t('cip.cmn.components.basicControl.field.informationTechology'), max: 30000 },
          { name: i18n.t('cip.cmn.components.basicControl.field.customerSupport'), max: 38000 },
          { name: i18n.t('cip.cmn.components.basicControl.field.development'), max: 52000 },
          { name: i18n.t('cip.cmn.components.basicControl.field.marketing'), max: 25000 }
        ]
      },
      series: [{
        name: i18n.t('cip.cmn.components.basicControl.field.vs'),
        type: 'radar',

        // areaStyle: {normal: {}},
        data: [{
            value: [4300, 10000, 28000, 35000, 50000, 19000],
            name: i18n.t('cip.cmn.components.basicControl.field.allocatedBudget'),
            itemStyle: { color: '#ff3b3b' }
          },
          {
            value: [5000, 14000, 28000, 31000, 42000, 21000],
            name: i18n.t('cip.cmn.components.basicControl.field.actualSpending'),
            itemStyle: { color: '#1890ff' }
          }
        ]
      }]
    },
    w: 6,
    h: 8,
    minW: 4,
    minH: 8,
    maxW: 12,
    maxH: 20
  }
]
