var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-aside",
    { staticClass: "right-box", attrs: { width: "300px" } },
    [
      _c("div", { staticClass: "cap-wrapper" }, [
        _vm._v(
          _vm._s(_vm.$t("cip.plat.sys.visual3.field.ComponentProperties"))
        ),
      ]),
      _c(
        "el-scrollbar",
        { staticClass: "aside-scrollbar" },
        [
          _c(
            "el-form",
            {
              attrs: {
                "label-width": "80px",
                labelPosition: "left",
                size: "small",
              },
            },
            [
              _vm.activeData
                ? [
                    _vm.activeData.title !== undefined
                      ? _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("cip.plat.sys.visual3.field.title"),
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: _vm.$t(
                                  "cip.plat.sys.visual3.msg.titleWarning"
                                ),
                              },
                              model: {
                                value: _vm.activeData.title,
                                callback: function ($$v) {
                                  _vm.$set(_vm.activeData, "title", $$v)
                                },
                                expression: "activeData.title",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.activeData.snowsKey === "commonFunc"
                      ? [
                          _c("el-divider", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("cip.plat.sys.visual3.field.option")
                              )
                            ),
                          ]),
                          _vm._l(_vm.activeData.list, function (item, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "select-item" },
                              [
                                _c("SNOWStreeSelect", {
                                  attrs: {
                                    options: _vm.menuList,
                                    placeholder: _vm.$t(
                                      "cip.plat.sys.visual3.msg.menuWarning"
                                    ),
                                    lastLevel: "",
                                    clearable: "",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.getSelectValue(
                                        arguments,
                                        index
                                      )
                                    },
                                  },
                                  model: {
                                    value: item.id,
                                    callback: function ($$v) {
                                      _vm.$set(item, "id", $$v)
                                    },
                                    expression: "item.id",
                                  },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "close-btn select-line-icon",
                                    on: {
                                      click: function ($event) {
                                        return _vm.delSelectItem(item, item.id)
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-remove-outline",
                                    }),
                                  ]
                                ),
                              ],
                              1
                            )
                          }),
                          _c(
                            "div",
                            { staticClass: "mt-10" },
                            [
                              _vm.activeData.list.length < 10
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { icon: "el-icon-plus" },
                                      on: { click: _vm.addSelectItem },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "cip.plat.sys.visual3.field.AddOptions"
                                          )
                                        ) + "\n            "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                    _vm.activeData.snowsKey === "dataBoard"
                      ? [
                          _vm._l(_vm.activeData.list, function (item, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "dataBoard-item" },
                              [
                                _c("p", { staticClass: "head" }, [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "cip.plat.sys.visual3.field.option"
                                        )
                                      ) + _vm._s(index + 1)
                                    ),
                                  ]),
                                  _c("a", {
                                    staticClass: "head-icon el-icon-close",
                                    on: {
                                      click: function ($event) {
                                        return _vm.delDataBoardItem(index)
                                      },
                                    },
                                  }),
                                ]),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "cip.plat.sys.visual3.field.title"
                                      ),
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "cip.plat.sys.visual3.msg.titleWarning"
                                        ),
                                      },
                                      model: {
                                        value: item.fullName,
                                        callback: function ($$v) {
                                          _vm.$set(item, "fullName", $$v)
                                        },
                                        expression: "item.fullName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "cip.plat.sys.visual3.field.dataType"
                                      ),
                                    },
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value: item.dataType,
                                          callback: function ($$v) {
                                            _vm.$set(item, "dataType", $$v)
                                          },
                                          expression: "item.dataType",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio-button",
                                          { attrs: { label: "static" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "cip.plat.sys.visual3.field.StaticData"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-radio-button",
                                          { attrs: { label: "dynamic" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "cip.plat.sys.visual3.field.RemoteData"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                item.dataType === "dynamic"
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: _vm.$t(
                                            "cip.plat.sys.visual3.field.dataInterface"
                                          ),
                                        },
                                      },
                                      [
                                        _c("interface-dialog", {
                                          attrs: {
                                            title: item.propsName,
                                            value: item.propsApi,
                                            popupTitle: _vm.$t(
                                              "cip.plat.sys.visual3.field.dataInterface"
                                            ),
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.propsUrlChange(
                                                arguments,
                                                index
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                item.dataType === "static"
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: _vm.$t(
                                            "cip.plat.sys.visual3.field.numericalValue"
                                          ),
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: _vm.$t(
                                              "cip.plat.sys.visual3.msg.numericalWarning"
                                            ),
                                          },
                                          model: {
                                            value: item.num,
                                            callback: function ($$v) {
                                              _vm.$set(item, "num", $$v)
                                            },
                                            expression: "item.num",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "cip.plat.sys.visual3.field.Icon"
                                      ),
                                    },
                                  },
                                  [
                                    _c(
                                      "el-input",
                                      {
                                        attrs: {
                                          placeholder: _vm.$t(
                                            "cip.plat.sys.visual3.msg.iconWarning"
                                          ),
                                        },
                                        model: {
                                          value: item.icon,
                                          callback: function ($$v) {
                                            _vm.$set(item, "icon", $$v)
                                          },
                                          expression: "item.icon",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { slot: "append" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.openIconsDialog(
                                                  index
                                                )
                                              },
                                            },
                                            slot: "append",
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "cip.plat.sys.visual3.field.select"
                                                  )
                                                ) +
                                                "\n                "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c("el-divider"),
                              ],
                              1
                            )
                          }),
                          _vm.activeData.list.length < 6
                            ? _c(
                                "el-button",
                                {
                                  attrs: { icon: "el-icon-plus" },
                                  on: { click: _vm.addDataBoardItem },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "cip.plat.sys.visual3.field.AddOptions"
                                      )
                                    ) + "}\n          "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      : _vm._e(),
                    _vm.activeData.snowsKey &&
                    _vm.activeData.snowsKey.indexOf("Chart") > -1
                      ? [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "cip.plat.sys.visual3.field.dataType"
                                ),
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  attrs: { size: "small" },
                                  on: { change: _vm.dataTypeChange },
                                  model: {
                                    value: _vm.activeData.dataType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.activeData, "dataType", $$v)
                                    },
                                    expression: "activeData.dataType",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: "static" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "cip.plat.sys.visual3.field.StaticData"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: "dynamic" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "cip.plat.sys.visual3.field.RemoteData"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.activeData.dataType === "static"
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "cip.plat.sys.visual3.field.DataSettings"
                                    ),
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.showData(
                                            _vm.activeData.option
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("cip.cmn.btn.viewBtn"))
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.activeData.dataType === "dynamic"
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "cip.plat.sys.visual3.field.dataInterface"
                                    ),
                                  },
                                },
                                [
                                  _c("interface-dialog", {
                                    attrs: {
                                      title: _vm.activeData.propsName,
                                      value: _vm.activeData.propsApi,
                                      popupTitle: _vm.$t(
                                        "cip.plat.sys.visual3.msg.InterfaceWarning"
                                      ),
                                    },
                                    on: { change: _vm.propsApiChange },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      : _vm._e(),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _vm.areaVisible
        ? _c("JSONArea", { ref: "JSONArea", on: { refresh: _vm.updateOption } })
        : _vm._e(),
      _c("iconBox", {
        attrs: { current: _vm.current, visible: _vm.iconsVisible },
        on: {
          "update:visible": function ($event) {
            _vm.iconsVisible = $event
          },
          choiceIcon: _vm.setIcon,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }