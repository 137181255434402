var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "SNOWS-dialog SNOWS-dialog_center",
      attrs: {
        title: _vm.$t("cip.plat.sys.visual3.title.dataSettingsTitle"),
        "close-on-click-modal": false,
        visible: _vm.visible,
        "lock-scroll": "",
        width: "600px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c("JSONEditor", {
        staticClass: "preview",
        attrs: { mode: "json" },
        model: {
          value: _vm.responseData,
          callback: function ($$v) {
            _vm.responseData = $$v
          },
          expression: "responseData",
        },
      }),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("cip.cmn.btn.celBtn")))]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.closeDialog()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("cip.cmn.btn.defBtn")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }