var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    _vm._g(
      _vm._b(
        {
          staticClass: "SNOWS-full-dialog",
          attrs: {
            "close-on-click-modal": false,
            "modal-append-to-body": false,
            fullscreen: "",
            "lock-scroll": "",
            "show-close": false,
            modal: false,
          },
          on: { open: _vm.onOpen },
        },
        "el-dialog",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c("div", { staticClass: "SNOWS-full-dialog-header" }, [
        _c("div", { staticClass: "header-title" }, [
          _c("img", {
            staticClass: "header-logo",
            attrs: { src: require("@/assets/images/sinomas.png") },
          }),
          _c("p", { staticClass: "header-txt" }, [
            _vm._v(
              " ·" + _vm._s(_vm.$t("cip.plat.sys.visual3.title.PreviewTitle"))
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "options" },
          [
            _c(
              "el-button",
              {
                on: {
                  click: function ($event) {
                    return _vm.closeDialog()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("cip.cmn.btn.celBtn")))]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "main", staticStyle: { padding: "0" } },
        [_c("PortalLayout", { attrs: { layout: _vm.layout, mask: "" } })],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }