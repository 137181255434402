<template>
  <el-dialog :title="$t('cip.plat.sys.portal.field.addPortal')" :close-on-click-modal="false"
    :visible.sync="visible" class="SNOWS-dialog SNOWS-dialog_center" lock-scroll width="600px">
    <el-form ref="dataForm" :model="dataForm" :rules="dataRule" v-loading="loading"
      label-width="80px">
      <el-form-item :label="$t('cip.plat.sys.portal.field.name')" prop="fullName">
        <el-input v-model="dataForm.fullName" :placeholder="$t('cip.plat.sys.portal.field.name')" maxlength="100">
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('cip.plat.sys.portal.field.enCode')" prop="enCode">
        <el-input v-model="dataForm.enCode" :placeholder="$t('cip.plat.sys.portal.field.enCode')" maxlength="50">
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('cip.plat.sys.portal.field.portal')" prop="category">
        <el-select v-model="dataForm.category" :placeholder="$t('cip.plat.sys.portal.field.portal')">
          <el-option :key="item.dictKey" :label="item.dictValue" :value="item.dictKey"
            v-for="item in categoryList" />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('cip.plat.sys.portal.field.linkType')" prop="linkType">
        <el-select v-model="dataForm.linkType" :placeholder="$t('cip.plat.sys.portal.field.linkType')">
          <el-option label="页面" :value="0" />
          <el-option label="外链" :value="1" />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('cip.plat.sys.portal.field.linkPath')" prop="customUrl">
        <el-input v-model="dataForm.customUrl" :placeholder="$t('cip.plat.sys.portal.field.linkPath')">
          <template slot="prepend" v-if="dataForm.linkType===0">@/views/</template>
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('cip.plat.sys.portal.field.sort')" prop="sortCode">
        <el-input-number :min="0" :max="999999" v-model="dataForm.sortCode"
          controls-position="right" />
      </el-form-item>
<!--      <el-form-item :label="$t('cip.plat.sys.portal.field.status')" prop="enabledMark">
        <el-switch v-model="dataForm.enabledMark" :active-value="'2'" :inactive-value="'1'" />
      </el-form-item>-->
      <el-form-item :label="$t('cip.plat.sys.portal.field.remark')" prop="description">
        <el-input v-model="dataForm.description" :placeholder="$t('cip.plat.sys.portal.field.remark')" type="textarea" :rows="3" />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">{{$t('cip.cmn.btn.celBtn')}}</el-button>
      <el-button type="primary" :loading="btnLoading" @click="dataFormSubmit()">
        {{$t('cip.cmn.btn.defBtn')}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getPortalInfo, Submit } from '@/api/onlineDev/portal'

export default {
  data() {
    return {
      visible: false,
      loading: false,
      btnLoading: false,
      categoryList: [],
      dataForm: {
        id: '',
        fullName: '',
        enCode: '',
        sortCode: 0,
        enabledMark: 1,
        type: 1,
        linkType: null,
        customUrl: '',
        category: '',
        description: ""
      },
      dataRule: {
        fullName: [
          { required: true, message: '门户名称不能为空', trigger: 'blur' },
          {
            max:60,
            message: '长度不能大于60'
          },
          {
            pattern: /^\S.*\S$|(^\S{0,1}\S$)/,
            message: '请删除前后空格',
          }
        ],
        enCode: [
          { required: true, message: '门户编码不能为空', trigger: 'blur'},
          {
            max:50,
            message: '长度不能大于50'
          },
          {
            pattern: /^\S.*\S$|(^\S{0,1}\S$)/,
            message: '请删除前后空格',
          }
        ],
        category: [
          { required: true, message: '门户分类不能为空', trigger: 'change' },
        ],
        linkType: [
          { required: true, message: '链接类型不能为空', trigger: 'change' },
        ],
        customUrl: [
          { required: true, message: '链接地址不能为空', trigger: 'blur' },
          {
            max:1000,
            message: '长度不能大于1000'
          },
          {
            pattern: /^\S.*\S$|(^\S{0,1}\S$)/,
            message: '请删除前后空格',
          }
        ]
      }
    }
  },
  methods: {
    init(categoryList, id) {
      this.categoryList = categoryList
      this.dataForm.id = id || ''
      this.visible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields()
        if (this.dataForm.id) {
          this.loading = true
          getPortalInfo(this.dataForm.id).then(res => {
            this.dataForm = res.data.data
            this.loading = false
          }).catch(() => { this.loading = false })
        }
      })
    },
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) return
        this.btnLoading = true
        const formMethod = this.dataForm.id ? Update : Submit;
        formMethod(this.dataForm).then((res) => {
          this.$message({
            message: res.data.msg,
            type: 'success',
            duration: 1500,
            onClose: () => {
              this.visible = false
              this.btnLoading = false
              this.$emit('close', true)
            }
          })
          this.$emit('form1', this.closeForm1)
        }).catch(() => { this.btnLoading = false })
      })
    }
  }
}
</script>
